.crypto___ {
    color: var(--txt-white);

    .row {
        row-gap: 15px;
    }

    .__sec_header {
        text-align: center;
        max-width: 800px;
        width: 100%;
        margin: 0 auto 40px;

        h6 {
            opacity: 0.5;
        }
    }


    .crypto__banner {
        position: relative;
        z-index: 1;
        overflow: hidden;
        height: 90vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 70px;
        padding-bottom: 30px;

        @media ($breakpoint_MD) {
            height: auto;
            padding-bottom: 30px;
        }


        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-image: url(../Image/tiffany-blur.webp);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0.4;
        }

        .crypto-data {
            text-align: center;
            max-width: 900px;
            width: 100%;
            margin: 50px auto 0;
            padding: 0 15px;
            transition: all 0.4s linear;

            .__img {
                img {
                    width: 20%;
                }
            }

            h3 {
                font-size: 44px;
                font-weight: 600;
                margin-bottom: 10px;
            }


            p {
                margin-bottom: 0;
                opacity: 0.6;
                max-width: 700px;
                width: 100%;
                margin: auto;
            }

            a {
                text-decoration: none;
                display: inline-block;
                margin-top: 40px;
                background: var(--q-btn);
                color: var(--txt-white);
                border: 1px solid transparent;
                padding: 8px 25px;
                border-radius: var(--q-radius);
                text-decoration: none;
                transition: all 0.4s linear;

                svg {
                    margin-left: 10px;
                }

                &:hover {
                    background: transparent;
                    border-color: var(--q-border);
                }

            }
        }
    }

    .__sec_about {
        padding: 100px 0;

        .__sec_header {
            text-align: center;
            max-width: 800px;
            width: 100%;
            margin: 0 auto 40px;

            h6 {
                opacity: 0.5;
            }

            .__btns {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                max-width: 400px;
                margin: 20px auto;
                width: 100%;

                .btn {
                    background-color: #ffffff0d;
                    border: 1px solid #ffffff5c;
                    color: var(--txt-white);
                    padding: 10px 20px;
                    border-radius: 30px;
                    transition: all 0.2s linear;
                    width: 100%;
                    white-space: nowrap;
                }

                .active {
                    background-color: var(--primary-color);
                }
            }
        }

        .__sec-img {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 85%;
                height: 90%;
                transform: translate(-50%, -50%) scale(1.1);
                border-radius: 50%;
                background-color: #ffffff0d;
                z-index: -1;

            }

            img {
                width: 90%;
                height: 85%;
                overflow: hidden;
                position: relative;
                top: -20px;


                @media ($breakpoint_MD) {
                    width: 40%;
                    height: 40%;
                }
            }
        }

        .__sec-card {

            h2 {
                margin-bottom: 20px;
                text-transform: capitalize;

                svg {
                    margin-right: 10px;
                    font-size: 33px;
                }
            }

            h5 {
                margin-bottom: 20px;
                font-size: 16px;

            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;

                li {
                    font-size: 15px;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;
                    opacity: 0.8;

                    svg {
                        color: var(--primary-color);
                    }
                }
            }


        }
    }

    .__swap{
        padding: 50px 50px 100px;
        border-top: 1px solid #ffffff0d;
        background-color: #ffffff0a;

        .row{
            justify-content: center;
            row-gap: 14px;
        }


        .__swapCard{
            background-color: #ffffff0d;
            padding: 20px;
            border: #ffffff5c;
            height: 100%;
            border-radius: 14px;
            transition: all 0.2s linear;

            &:hover{
                background-color: var(--primary-color);

                .__icon-swap{
                    background-color: #000;
                }
            }

            .__icon-swap{
                margin-bottom: 20px;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--primary-color);
                display: inline-block;
                border-radius: 10px;
                transition: all 0.2s linear;
            }

            h4{
                margin-bottom: 0;
                font-size: 20px;
            }
        }
    }

    .__buySell{
        padding-bottom: 50px;

        .__buyCard{
            padding: 20px;
            background-color: #ffffff0d;
            padding: 20px;
            border-radius: 14px;

            .__cardslider{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                background-color: #32373d;
                border-radius: 20px;
                overflow: hidden;
                padding: 15px;

                @media ($breakpoint_SM){
                    flex-direction: column;
                    justify-content:center;
                }

                .__slider__img{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img{
                        width: 80%;
                        height: 80%;
                    }
                }

                .__slider__txt{
                    width: 100%;
                    padding-top: 50px;

                    h4{
                        margin-bottom: 20px;
                        font-size: 33px;
                        line-height: 1.4;
                    }

                    p{
                        font-size: 18px;
                        opacity: 0.7;
                        margin-bottom: 8px;
                        padding-left: 14px;
                        position: relative;
                        z-index: 1;


                        &::after{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width:5px;
                            background-color: var(--primary-color);
                        }
                    }
                    
                }

            }

        }

    }



}