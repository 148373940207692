.__pheader {
    position: absolute;
    width: 100%;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: translateY(0);
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff08;
    padding: 20px 70px;
    width: 100%;
    font-family: var(--font-Montserrat);
    z-index: 9999;


    @media ($breakpoint_XL) {
        padding: 20px 15px;
        box-shadow: -1px 4px 20px 0px #ffffff3d;
    }

    .__phLeft {
        a {
            display: block;

        }

        img {
            height: 36px;
            width: auto;
        }
    }

    .__phRight {

        .nav {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            gap: 15px;

            @media ($breakpoint_XL) {
                display: unset !important;
                position: fixed;
                top: -100vh;
                // opacity: 0;
                // visibility: hidden;
                right: 0;
                background: var(--pHeaderShow);
                padding: 0;
                height: 100vh;
                width: 300px;
                flex-direction: column;
                z-index: 99999;
                transition: top 0.3s ease-in-out;
            }

            @media ($breakpoint_SM) {
                width: 100%;
            }


            .phoneHeader {
                display: none;

                @media ($breakpoint_XL) {
                    display: flex !important;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px;
                    background: var(--pHeader);
                    margin-bottom: 20px;
                }

                a {
                    display: inline-block;
                    width: 5rem;

                    @media ($breakpoint_SM) {
                        width: 3rem;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

            .nav-item {
                @media ($breakpoint_XL) {
                    width: 100%;
                    display: block;
                }
            }

            .nav-link {
                display: inline-block;
                font-size: 14px;
                color: var(--txt-white);
                text-decoration: none;
                padding: 10px;
                position: relative;
                z-index: 2;
                transition: all .3s linear;

                @media ($breakpoint_XL) {
                    all: unset;
                    display: block !important;
                    padding: 10px 20px !important;
                    color: var(--txt-white);
                    text-decoration: none;
                    border-radius: 0 !important;
                    border: 0 !important;
                    font-size: 15px;
                    cursor: pointer !important;

                    &:hover {
                        background: var(--pHeader);
                    }
                }

                &:hover {
                    color: var(--primary-color);
                }

                .__dropIcon {
                    font-size: 15px;
                }
            }

            .dropdownThis {
                position: relative;
                z-index: 1;
                display: none;

                &:hover {

                    .__dropdown {
                        opacity: 1;
                        visibility: visible;
                        z-index: 1;
                        transform: perspective(1px) translateY(40px);
                    }
                }

                .__dropdown {
                    position: absolute;
                    top: 0;
                    right: 0%;
                    width: 280px;
                    opacity: 0;
                    padding-top: 10px;
                    visibility: hidden;
                    transform: perspective(2px) translateY(100px);
                    transition: all .2s cubic-bezier(.5, 0, 0, 1.25), opacity 0.1s ease-out;

                    .__dropcard {
                        background-color: seagreen;
                        border-radius: 10px;
                        padding: 10px;



                        .__dropLink {
                            display: block;
                            width: 100%;
                            color: var(--txt-white);
                            text-decoration: none;
                            font-size: 15px;
                            padding: 10px;
                            background-color: #ddd;
                        }
                    }
                }

            }

            .buttonThis {

                @media ($breakpoint_XL) {
                    margin-top: 20px;
                }
            }

        }



        .buttonThis {
            background: var(--q-btn);
            border: 1px solid transparent;
            padding: 10px 25px !important;
            font-weight: 600;
            border-radius: var(--q-radius);
            text-decoration: none;
            transition: all 0.4s linear;
            color: var(--txt-white);

            &:hover {
                background: transparent;
                border-color: var(--q-border);
            }

            @media ($breakpoint_SM) {
                padding: 8px 12px !important;
                font-size: 8px;
            }
        }

        .__phoneView {
            display: none;

            @media ($breakpoint_XL) {
                display: block;
            }
        }

        .toggleBtn {
            background-color: transparent;
            padding: 10px;
            outline: none !important;
            box-shadow: unset !important;
            border: 0 !important;
            margin-left: 20px;

            svg {
                color: var(--txt-white);
                font-size: 2rem;
            }
        }

    }

    .phone-nav {
        top: 0 !important;
        opacity: 1 !important;
        visibility: visible !important;

    }
}


.__pheader.hidden {
    transform: translateY(-100%);
    opacity: 0;
}

.__pheader.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    opacity: 1;
    transform: translateY(0);
    background: var(--pHeaderShow) !important;
}

.__overlYheader {
    display: none;

    @media ($breakpoint_XL) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #353535;
        z-index: 999;
    }
}

.showOvely {
    display: block;
}