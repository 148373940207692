@import './Header.scss';

@import './Footer.scss';

.__custom_container {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 0 60px;

    @media ($breakpoint_SM) {
        padding: 0 15px;
    }
}

body {
    position: relative;
    font-family: var(--font-Titillium);
    background: var(--BannerBG);

}

body.no-scroll {
    overflow: hidden;
}



.__animation {
    position: absolute;
    z-index: 99999;
    top: 296px;
    right: 31%;

    img {
        width: 13rem;
    }

}

.stop-animation {
    transition: none;
}


.__vf_banner {
    height: 100vh;
    width: 100%;
    font-family: var(--font-Titillium);
    background: var(--BannerBG);
    color: var(--txt-white);
    overflow: hidden;
    position: relative;
    z-index: 1;


    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 100%;
        top: 50px;
        right: 0;
        background-image: url(../Image/header-bg_new.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: .07;

        @media ($breakpoint_XL) {
            background-size: cover;
        }
    }

    @media ($breakpoint_XL) {
        height: auto;
        padding-top: 150px;
        text-align: center;
    }

    .__custom_container {
        height: 100%;

        @media ($breakpoint_XL) {
            padding: 0 120px;
        }

        @media ($breakpoint_MD) {
            padding: 0 15px;
        }
    }

    .row {
        height: 100%;
        align-items: center;
    }

    .__bannerLeft {
        padding-top: 100px;

        @media ($breakpoint_XL) {
            padding-bottom: 40px;
        }

        @media ($breakpoint_SM) {
            padding-top: 0;
        }

        h2 {
            color: white;
            font-size: 2.5rem;
            margin-bottom: 1rem;
            font-weight: bold;

            span {
                color: var(--primary-color);
                font-size: 64px;

                @media ($breakpoint_SM) {
                    font-size: 2rem;
                }
            }

            @media ($breakpoint_SM) {
                font-size: 2rem;
            }
        }

        p {
            opacity: 0.8;
            font-size: 0.8rem;
            font-family: var(--font-Montserrat);

            @media ($breakpoint_SM) {
                font-size: 14px;
            }
        }

        .__moreLink {
            margin-top: 30px;

            .btn {
                background: var(--q-btn);
                color: var(--txt-white);
                border: 1px solid transparent;
                padding: 10px 25px;
                border-radius: var(--q-radius);
                text-decoration: none;
                transition: all 0.4s linear;

                svg {
                    margin-right: 10px;
                }

                &:hover {
                    background: transparent;
                    border-color: var(--q-border);
                }

            }
        }
    }

    .__bannerRightcoumn {

        .__bannerRight {
            display: flex;
            align-items: center;
            justify-content: center;

            @media ($breakpoint_XL) {
                padding-bottom: 20px;
            }

            img {
                width: 100%;
                height: 100%;
                transform: scale(.8);
                position: relative;
                // filter: drop-shadow(0px 0px 72px #14a4d8);
                top: 40px;
                animation: shadow 1s infinite alternate-reverse linear;

                @keyframes shadow {
                    0% {
                        filter: drop-shadow(0px 0px 40px #14a4d8);
                    }

                    100% {
                        filter: drop-shadow(0px 0px 72px #14a4d8);
                    }
                }

                @media ($breakpoint_XL) {
                    width: 60%;
                    height: 60%;
                    transform: none;
                    top: 0;
                    right: 0;
                }

                @media ($breakpoint_MD) {
                    width: 40%;
                    height: 40%;
                    object-fit: contain;
                }

                @media ($breakpoint_SM) {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }


}


.__infoBanner {
    background-color: #1a1a1b;
    padding: 50px 0;
    color: var(--txt-white);
    position: relative;
    z-index: 1;
    margin-top: 40px;


    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 100%;
        top: -120px;
        right: 0;
        background-image: url(../Image/dashed.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .__custom_container {
        padding-bottom: 90px;

    }


    .row {
        align-items: stretch;
        row-gap: 14px;
    }

    .__infoBannerHeader {
        text-align: center;
        max-width: 600px;
        width: 100%;
        margin: 0 auto 40px;
        text-transform: capitalize;

        h4 {
            font-size: 3rem;
            font-weight: 600;

            @media ($breakpoint_SM) {
                font-size: 2rem;
            }
        }
    }

    .____infoCard {
        height: 100%;
        background-color: var(--BannerBG);
        padding: 20px;
        font-size: 14px;
        border: 0.6px solid #eeeeee21;
        color: #eee;
        overflow: hidden;
        transition: all 0.3s linear;
        border-radius: 20px;


        .__iinfoHeader {
            font-size: 22px;
            margin-bottom: 14px;
            text-transform: capitalize;
            color: var(--primary-color);
            font-weight: 600;
        }

        .data {
            transition: all 0.3s linear;
            font-family: var(--font-Poppins);
            font-size: 14px;
        }
    }
}

.__blockchain {
    background-color: var(--secondBG);
    padding: 50px 0;
    color: var(--txt-white);


    .__blockchainCard {
        background: linear-gradient(180deg, #15110F 0%, rgba(27, 25, 25, 0) 100%);
        padding: 40px 60px;
        max-width: 1200px;
        margin: auto;
        width: 100%;
        border-radius: 20px;
        font-family: var(--font-Titillium);
        overflow: hidden;

        @media ($breakpoint_MD) {
            padding: 40px 30px;

        }

        @media ($breakpoint_SM) {
            padding: 40px 20px;
        }

        .row {
            align-items: center;
        }


        h3 {
            margin-bottom: 20px;
            font-size: 2.5rem;
            font-weight: 600;

            @media ($breakpoint_SM) {
                font-size: 1.7rem;
            }
        }

        p {
            opacity: 0.8;

            @media ($breakpoint_SM) {
                font-size: 13px;
            }
        }

        .btn {
            display: inline-block;
            background: var(--q-btn);
            color: var(--txt-white);
            border: 1px solid transparent;
            padding: 10px 25px;
            border-radius: var(--q-radius);
            text-decoration: none;
            transition: all 0.4s linear;
            margin-top: 20px;

            @media ($breakpoint_SM) {
                padding: 7px 15px;
                font-size: 13px;
                margin-bottom: 20px;
            }

            &:hover {
                background: transparent;
                border-color: var(--q-border);
            }
        }

        .__b-right {
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            margin-top: 40px;
            position: relative;
            z-index: 1;

            &::after {
                content: "";
                position: absolute;
                z-index: -1;
                height: 100%;
                width: 100%;
                top: 80px;
                right: 0;
                background-image: url(../Image/shine.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                animation: rotation 8s infinite linear;
                opacity: .3;
            }

            @keyframes rotation {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }

            @media ($breakpoint_LG) {
                justify-content: center;

            }

            img {
                width: auto;
                height: 500px;

                @media ($breakpoint_LG) {
                    height: 60%;
                    width: 60%;
                }

            }
        }
    }
}


.__ai-section {
    padding: 100px 0;
    color: var(--txt-white);
    overflow: hidden;
    background-color: #000;

    @media ($breakpoint_SM) {}

    .__aiHeader {
        margin-bottom: 40px;

        h4 {
            font-size: 3rem;
            font-weight: 600;

            @media ($breakpoint_SM) {
                font-size: 1.8rem;
            }
        }

        p {
            font-size: 1rem;
            opacity: 0.8;

            @media ($breakpoint_SM) {
                font-size: 13px;
            }

        }
    }

    .__aiLeft {
        h5 {
            color: var(--primary-color);
            font-weight: 600;
            margin-bottom: 1rem;
        }

        .accordion {
            background-color: transparent;
        }

        .accordion-item {
            background-color: transparent;
            border: 0;
            margin-bottom: 1rem;

            .accordion-header {
                background-color: transparent;

                .accordion-button {
                    background-color: #d7d2d224;
                    border: 0 !important;
                    color: var(--txt-white);
                    border-radius: 10px !important;
                    box-shadow: unset !important;
                    outline: unset !important;

                    &::after {
                        background-color: #fff;
                    }
                }

                button[aria-expanded="true"] {
                    background-color: var(--primary-color) !important;

                    &::after {
                        background-color: transparent !important;
                    }

                }
            }

            .accordion-body {
                p {
                    color: var(--txt-white);
                }
            }
        }
    }

    .__aiRight {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;

        @media ($breakpoint_LG) {
            justify-content: center;
        }

        img {
            width: 92%;
            height: 88%;
            object-fit: contain;

            @media ($breakpoint_LG) {
                width: 80%;
                height: 80%;
            }

            @media ($breakpoint_SM) {
                width: 70%;
                height: 70%;
            }


        }

    }
}

.fees__section {
    padding: 100px 0;
    color: var(--txt-white);

    .__feesHeader {
        text-align: center;
        margin-bottom: 50px;

        p {
            opacity: 0.7;
        }

        h3 {
            font-weight: 600;
            font-size: 2rem;
        }
    }

    .__feesLeft {

        h5 {
            margin-bottom: 20px;

            @media ($breakpoint_SM) {
                font-size: 1rem;
                font-weight: 400;
                margin: 20px 0;
            }
        }

        p {
            opacity: 0.8;
            padding-left: 1rem;
            margin-bottom: 10px;
        }
    }

    .__feesImg {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media ($breakpoint_LG) {
            justify-content: center;
        }


        img {
            width: 90%;
            height: 90%;

            @media ($breakpoint_LG) {
                width: 65%;
                height: 65%;
                margin-bottom: 20px;
            }

            @media ($breakpoint_SM) {
                width: 70%;
                height: 70%;
            }
        }
    }
}

.__proBlockchain {
    // background-color: #000;
    color: var(--txt-white);
    padding: 100px 0;
    z-index: 1;
    margin-top: 40px;
    position: relative;


    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        height: 80%;
        width: 80%;
        top: 0px;
        left: 0;
        background-image: url(../Image/bg_faq_new.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: .1;
    }

    .for_space_media_ {
        @media ($breakpoint_MD) {
            gap: 30px;
        }
    }

    .__topHeader {
        text-align: center;
        max-width: 900px;
        width: 100%;
        margin: 0 auto 50px;

        h4 {
            font-weight: 600;
            font-size: 2rem;
            margin-bottom: 1.5rem;
        }

        p {
            @media ($breakpoint_SM) {
                font-size: 13px;
            }
        }
    }

    .__blockchainVB {
        background-color: var(--pHeader);
        padding: 20px;
        height: 100%;
        transition: all 0.4s linear;



        &:hover {
            background-color: var(--primary-color);
        }

        .Icon {
            width: 3rem;
            height: 3rem;
            background-color: var(--primary-color);
            color: var(--txt-white);
            border-radius: 10px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        p {
            font-size: 15px;

        }
    }

}


.__tokenar {
    color: var(--txt-white);
    padding: 100px 0;
    background-color: #00000038;


    .__tokenHeader {
        text-align: center;
        max-width: 900px;
        width: 100%;
        margin: 0 auto 50px;

        h4 {
            font-weight: 600;
            font-size: 2rem;
            margin-bottom: 1.5rem;
        }
    }

    ul {
        background-color: #5858580a;
        border-radius: 14px;
        padding: 20px;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #76767621;
            margin-bottom: 14px;
            padding: 10px 15px;

            p {
                margin-bottom: 0;

                a {
                    text-decoration: none;
                    color: var(--primary-color);
                    border-radius: 10px;
                }
            }
        }
    }

    .accordion {
        background-color: transparent;
        max-width: 800px;
        width: 100%;
        margin: auto;
    }

    .accordion-item {
        background-color: transparent;
        border: 0;
        margin-bottom: 1rem;

        .accordion-header {
            background-color: transparent;

            .accordion-button {
                background: linear-gradient(90deg, rgba(245, 245, 245, 0) 2%, #14a3d791 100%);
                border: 0 !important;
                color: var(--txt-white);
                border-radius: 10px !important;
                box-shadow: unset !important;
                outline: unset !important;
                padding: 20px 20px;

                &::after {
                    background-color: #0bbeff;
                    padding: 10px;
                    border-radius: 10px;
                    transition: all .2s linear;
                }
            }

            button[aria-expanded="true"] {
                background: linear-gradient(270deg, rgba(245, 245, 245, 0) 2%, #14a3d791 100%) !important;

                &::after {
                    border-radius: 3px !important;
                }

            }
        }

        .accordion-body {
            p {
                color: var(--txt-white);
            }
        }
    }
}

.__app {
    padding: 100px 0;
    color: var(--txt-white);

    @media ($breakpoint_LG) {
        padding: 100px 0 0;
    }

    .__appLeft {
        h3 {
            font-weight: 600;
            margin-bottom: 20px;
            font-size: 3.4rem;

            @media ($breakpoint_SM) {
                font-size: 2rem;
            }
        }

        p,
        h5 {
            font-size: 15px;
            opacity: 0.8;

            @media ($breakpoint_SM) {
                font-size: 13px;
            }
        }

        .btn {
            margin-top: 20px;
            display: inline-block;
            background: var(--q-btn);
            color: var(--txt-white);
            border: 1px solid transparent;
            padding: 10px 25px;
            border-radius: var(--q-radius);
            text-decoration: none;
            transition: all 0.4s linear;
            margin-top: 20px;

            &:hover {
                background: transparent;
                border-color: var(--q-border);
            }
        }
    }

    .__appRyt {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 80%;
            height: 80%;
            object-fit: contain;

            @media ($breakpoint_LG) {
                width: 50%;
                height: 50%;
                margin-top: 20px;

            }

            @media ($breakpoint_SM) {
                width: 60%;
                height: 60%;
            }
        }
    }
}