.about__ {
    color: var(--txt-white);

    .row {
        row-gap: 15px;
    }

    .ab-banner {
        position: relative;
        z-index: 1;
        overflow: hidden;
        height: 100vh;
        // border-radius: 0 0 10% 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 70px;

        @media ($breakpoint_MD) {
            height: auto;
            padding-bottom: 60px;
        }


        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-image: url(../Image/tiffany-blur.webp);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0.4;
        }

        .ab-banner-data {
            text-align: center;
            max-width: 900px;
            width: 100%;
            margin: 50px auto 0;
            padding: 0 15px;
            transition: all 0.4s linear;

            .__img {
                img {
                    width: 20%;
                }
            }

            h3 {
                font-size: 44px;
                font-weight: 600;
                margin-bottom: 20px;
            }

            h6 {
                opacity: 0.5;
            }

            p {
                margin-top: 20px;
                margin-bottom: 0;
            }

            a {
                text-decoration: none;
                display: inline-block;
                margin-top: 40px;
                background: var(--q-btn);
                color: var(--txt-white);
                border: 1px solid transparent;
                padding: 8px 25px;
                border-radius: var(--q-radius);
                text-decoration: none;
                transition: all 0.4s linear;

                svg {
                    margin-left: 10px;
                }

                &:hover {
                    background: transparent;
                    border-color: var(--q-border);
                }

            }
        }
    }

    .__sec_about {
        padding: 100px 0;

        .__sec_header {
            text-align: center;
            max-width: 800px;
            width: 100%;
            margin: 0 auto 90px;

            h6 {
                opacity: 0.5;
            }
        }

        .__sec-img {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 80%;
                height: 80%;
                border-radius: 51% 49% 29% 71% / 36% 45% 55% 64%;
                overflow: hidden;


                @media ($breakpoint_MD) {
                    width: 40%;
                    height: 40%;
                }
            }
        }

        .__sec-card {

            h4 {
                font-size: 33px;
                font-weight: 600;
                padding-left: 20px;
                position: relative;
                z-index: 1;


                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4px;
                    height: 100%;
                    background-color: var(--primary-color);
                }
            }

            p {
                opacity: 0.8;
                margin-top: 25px;
                padding-left: 20px;
            }
        }
    }

    .__sec_team {

        .__sec_header {
            text-align: center;
            max-width: 800px;
            width: 100%;
            margin: 0 auto 90px;

            h6 {
                opacity: 0.5;
            }

            h1 {
                font-size: 44px;
                font-weight: bold;
            }
        }

        .__sec-team {
            background-color: #fffbfb0a;
            border-radius: 20px;
            padding: 14px;
            overflow: hidden;

            .__imgTeam {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                overflow: hidden;

                img {
                    width: 80%;
                    border-radius: 20px;
                    overflow: hidden;


                    @media ($breakpoint_MD) {
                        width: 60%;
                    }
                }
            }

            .__teamdata {
                margin-top: 20px;
                padding: 20px 20px 0;

                ._imgName {
                    p {
                        opacity: 0.7;
                    }
                }


                .__dis {
                    overflow: hidden;
                    opacity: 0.8;
                    position: relative;
                    z-index: 1;


                    .-btnsc {
                        display: inline-block;
                        color: var(--primary-color);
                    }

                    .-more {
                        position: absolute;
                        top: -7px;
                        right: 0;
                    }
                }

                .-half {
                    height: 30px;
                    padding-right: 40px;
                }

                .-full {
                    height: 100%;
                    padding-right: 0;

                }


            }
        }
    }


    .services__a {
        padding: 100px 0;

        .__sec_header {
            text-align: center;
            max-width: 800px;
            width: 100%;
            margin: 0 auto 20px;

            p {
                opacity: 0.5;
            }

            h1 {
                font-size: 44px;
                font-weight: bold;
            }
        }

        .sec___custom_container {
            max-width: 800px;
            width: 100%;
            margin: auto;
            padding: 0 15px;

            .row {
                row-gap: 20px;
            }
        }

        .serviceCard {
            background-color: #fffbfb0a;
            border-radius: 14px;
            padding: 14px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            p {
                margin-bottom: 0;
                font-size: 17px;
                background-color: var(--primary-color);
                padding: 10px;
                border-radius: 10px;
            }

            h3 {
                margin-bottom: 0;
                font-size: 17px;
            }
        }

    }

    .__document {

        .__sec_header {
            text-align: center;
            max-width: 800px;
            width: 100%;
            margin: 0 auto 20px;

            h6 {
                opacity: 0.5;
            }

            h1 {
                font-size: 44px;
                font-weight: bold;
            }
        }

        .__docuentcard {
            padding: 10px;

            img {
                width: 100%;
            }
        }
    }


}



.team__ {
    color: var(--txt-white);

    .row {
        row-gap: 15px;
    }

    .team__banner {
        position: relative;
        z-index: 1;
        overflow: hidden;
        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 70px;

        @media ($breakpoint_MD) {
            height: auto;
            padding-bottom: 60px;
        }


        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-image: url(../Image/tiffany-blur.webp);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0.4;
        }

        .team-data {
            text-align: center;
            max-width: 900px;
            width: 100%;
            margin: 50px auto 0;
            padding: 0 15px;
            transition: all 0.4s linear;

            .__img {
                img {
                    width: 40%;
                }
            }

            h3 {
                font-size: 44px;
                font-weight: 600;
                margin-bottom: 0px;
            }


            p {
                margin-bottom: 0;
                opacity: 0.6;
            }

            a {
                text-decoration: none;
                display: inline-block;
                margin-top: 40px;
                background: var(--q-btn);
                color: var(--txt-white);
                border: 1px solid transparent;
                padding: 8px 25px;
                border-radius: var(--q-radius);
                text-decoration: none;
                transition: all 0.4s linear;

                svg {
                    margin-left: 10px;
                }

                &:hover {
                    background: transparent;
                    border-color: var(--q-border);
                }

            }
        }
    }

    .__sec_team {
        padding: 100px 0;

        .__sec_header {
            text-align: center;
            max-width: 800px;
            width: 100%;
            margin: 0 auto 90px;

            h6 {
                opacity: 0.5;
            }

            h1 {
                font-size: 44px;
                font-weight: bold;
            }
        }

        .__sec-team {
            background-color: #fffbfb0a;
            border-radius: 20px;
            padding: 14px;
            overflow: hidden;

            .__imgTeam {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                overflow: hidden;

                img {
                    width: 80%;
                    border-radius: 20px;
                    overflow: hidden;


                    @media ($breakpoint_MD) {
                        width: 60%;
                    }
                }
            }

            .__teamdata {
                margin-top: 20px;
                padding: 20px 20px 0;

                ._imgName {
                    p {
                        opacity: 0.7;
                    }
                }


                .__dis {
                    overflow: hidden;
                    opacity: 0.8;
                    position: relative;
                    z-index: 1;


                    .-btnsc {
                        display: inline-block;
                        color: var(--primary-color);
                    }

                    .-more {
                        position: absolute;
                        top: -7px;
                        right: 0;
                    }
                }

                .-half {
                    height: 30px;
                    padding-right: 40px;
                }

                .-full {
                    height: 100%;
                    padding-right: 0;

                }


            }
        }
    }
}




.crypto___ {
    color: var(--txt-white);

    .row {
        row-gap: 15px;
    }

    .__sec_header {
        text-align: center;
        max-width: 800px;
        width: 100%;
        margin: 0 auto 40px;

        h6 {
            opacity: 0.5;
        }
    }


    .crypto__banner {
        position: relative;
        z-index: 1;
        overflow: hidden;
        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 70px;

        @media ($breakpoint_MD) {
            height: auto;
            padding-bottom: 60px;
        }


        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-image: url(../Image/tiffany-blur.webp);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0.4;
        }

        .crypto-data {
            text-align: center;
            max-width: 900px;
            width: 100%;
            margin: 50px auto 0;
            padding: 0 15px;
            transition: all 0.4s linear;

            .__img {
                img {
                    width: 20%;
                }
            }

            h3 {
                font-size: 44px;
                font-weight: 600;
                margin-bottom: 10px;
            }


            p {
                margin-bottom: 0;
                opacity: 0.6;
                max-width: 700px;
                width: 100%;
                margin: auto;
            }

            a {
                text-decoration: none;
                display: inline-block;
                margin-top: 40px;
                background: var(--q-btn);
                color: var(--txt-white);
                border: 1px solid transparent;
                padding: 8px 25px;
                border-radius: var(--q-radius);
                text-decoration: none;
                transition: all 0.4s linear;

                svg {
                    margin-left: 10px;
                }

                &:hover {
                    background: transparent;
                    border-color: var(--q-border);
                }

            }
        }
    }

    .__sec__crypto{
        padding: 100px 0;

       

        .__sec-img {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 60%;
                height: 60%;
                overflow: hidden;


                @media ($breakpoint_MD) {
                    width: 40%;
                    height: 40%;
                }
            }
        }

        .__sec-card {

            h4 {
                font-size: 33px;
                font-weight: 600;
                padding-left: 20px;
                position: relative;
                z-index: 1;


                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4px;
                    height: 100%;
                    background-color: var(--primary-color);
                }
            }

            p {
                opacity: 0.8;
                margin-top: 25px;
                padding-left: 20px;
            }
        }
    }

    .key_point{
        padding: 50px 0;
        background-color: #ffffff14;


        .card_a{
            background-color: #1f1f1f;
            height: 100%;
            padding: 20px;
            border-radius: 14px;

            .__icon{
                svg{
                    color: var(--primary-color);
                }
            }

            .__twxt{
                margin-top: 20px;

                h4{
                    margin-bottom: 13px;
                    font-size: 18px;
                }

                p{
                    opacity: 0.6;
                    font-size: 14px;
                }
            }

        }

    }

    .__marketPage{
        padding: 50px 0;


        .crypto__img{
            display: flex;
            align-items: center;
            justify-content: flex-end;

            img{
                width: 100%;
                height: 100%;
            }

        }
    }

    .service__hng {

        .__sec_header {
            text-align: center;
            max-width: 800px;
            width: 100%;
            margin: 0 auto 20px;

            p {
                opacity: 0.5;
            }

            h1 {
                font-size: 44px;
                font-weight: bold;
            }
        }

        .sec___custom_container {
            max-width: 800px;
            width: 100%;
            margin: auto;
            padding: 0 15px;

            .row {
                row-gap: 20px;
            }
        }

        .serviceCard {
            background-color: #fffbfb0a;
            border-radius: 14px;
            padding: 14px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            p {
                margin-bottom: 0;
                font-size: 17px;
                background-color: var(--primary-color);
                padding: 10px;
                border-radius: 10px;
            }

            h3 {
                margin-bottom: 0;
                font-size: 17px;
            }
        }

    }


}