.contact_us_sec {
    padding-top: 112px;

    .contactinfo-sec {
        position: relative;
        z-index: 1;

        &::after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-image: url("../Image/contact_us.jpg");
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: contain 100%;
            background-attachment: fixed;
            filter: brightness(0.2);
        }

        .contact-content {
            padding: 120px 0;
            text-align: center;

            // backdrop-filter: blur(8px);
            h1 {
                color: $white;
            }

            .home-cont {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                gap: 5px;

                a {
                    color: $white;
                    text-decoration: none;
                }

                svg {
                    color: $white;
                    font-size: 15px;
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    .cards-sec {
        padding-top: 80px;

        .Product_sec {
            margin-bottom: 90px;

            .inner_content {

                .row_add {
                    margin-top: 60px;

                    @media ($breakpoint_SM) {
                        width: 100%;
                        margin: auto;
                    }

                    .left_sec {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            height: 348px;
                            width: auto;
                        }
                    }

                    .info_sec {
                        .cards_inner {
                            width: 70%;
                            margin: auto;
                            height: 300px;
                            background-color: black;
                            border-radius: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 30px;

                            @media ($breakpoint_LG) {
                                padding: 10px;
                                flex-direction: column;
                                height: auto;
                            }

                            .card {
                                background-color: transparent;
                                border: 1px solid transparent;
                                border-radius: 24px;
                                text-align: center;
                                padding: 20px;
                                width: 360px;
                                position: relative;
                                z-index: 1;

                                @media ($breakpoint_LG) {
                                    width: 100%;
                                }

                                &::after {
                                    content: "";
                                    position: absolute;
                                    z-index: -1;
                                    border-radius: 24px;
                                    top: -56px;
                                    right: 0;
                                    height: 40px;
                                    width: 100%;
                                    opacity: 0;
                                    background-color: #14a4d8;
                                    transition: all .3s ease-in-out;

                                    @media ($breakpoint_LG) {
                                        top: 4px;
                                        left: -32px;
                                        width: 10%;
                                        height: 208px;
                                    }

                                    @media ($breakpoint_SM) {
                                        top: 4px;
                                        left: -22px;
                                    }
                                }

                                &:hover {

                                    &::after {
                                        height: 330px;
                                        opacity: 1;

                                        @media ($breakpoint_LG) {
                                            width: 110%;
                                            height: 208px;
                                        }

                                        @media ($breakpoint_SM ) {
                                            width: 108%;
                                        }
                                    }

                                    svg {
                                        background-color: rgba(0, 0, 0, 0.657);
                                        color: white;
                                    }

                                    h2 {
                                        color: black;
                                    }

                                    p {
                                        color: #000;
                                    }
                                }

                                svg {
                                    height: 86px;
                                    width: 86px;
                                    background-color: transparent;
                                    padding: 6px;
                                    padding: 10px;
                                    border-radius: 50%;
                                    margin: auto;
                                    transition: all .3s ease-in-out;
                                    color: var(--primary-color);
                                }

                                h2 {
                                    font-family: var(--font_heading);
                                    color: white;
                                    font-size: 24px;
                                    transition: all .3s ease-in-out;

                                    @media ($breakpoint_LG) {
                                        font-size: 16px;
                                    }
                                }

                                p {
                                    font-size: 15px;
                                    color: rgb(202, 202, 202);
                                    font-weight: 200;
                                    transition: all .3s ease-in-out;
                                    margin-bottom: 5px;

                                    @media ($breakpoint_LG) {
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
    }

    .map-sec {
        margin-bottom: 90px;

        .map-col {
            padding: 0;

            @media ($breakpoint_LG) {
                padding: 0 10px;
            }

            .map-details {
                position: relative;

                @media ($breakpoint_LG) {
                    display: none;
                }

                img {
                    width: auto;
                    height: 420px;
                    border-radius: 15px 0 0 15px;
                    margin-right: -2px;
                    position: absolute;
                    right: -37px;
                    top: 19px;

                    @media ($breakpoint_LG) {
                        border-radius: 15px 15px 0 0;
                    }
                }
            }
        }

        .map-col {
            .cont-form {
                background: #00000024;
                padding: 50px 50px 50px 90px;
                border-radius: 0 15px 15px 0;

                @media ($breakpoint_LG) {
                    border-radius: 0 0 15px 15px;
                    padding: 50px;
                }

                @media ($breakpoint_450) {
                    padding: 20px;
                }

                h4 {
                    color: $white;
                    margin-bottom: 30px;
                    font-weight: 500;
                }

                .form-grps {
                    margin-bottom: 20px;

                    .form-txt {
                        width: 100%;
                        background: #14a4d81c;
                        border: 1px solid #14a4d86b;
                        display: block;
                        /* border: none; */
                        padding: 15px;
                        border-radius: 5px;
                        color: #fff;
                        line-height: 1;
                        height: 50px;
                        box-shadow: none;
                        outline: none;

                        &::placeholder {
                            color: white;
                        }
                    }

                }

                .sendmess {
                    padding: 5px 30px;
                    border-radius: 30px;
                    background-color: $white;
                    border: 2px solid var(--primary-color);
                    color: black;
                    outline: 4px solid $white;
                    transition: all 0.3s linear;
                    text-decoration: none;
                    width: 200px;
                    position: relative;
                    left: 23px;
                    top: 10px;

                    &:hover {
                        box-shadow: var(--shadow);
                        background-color: var(--primary-color);
                        color: white;
                    }

                    @media ($breakpoint_450) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}