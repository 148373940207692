input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}




//Breakpoints....
$breakpoint_XXXL: 'max-width: 1500px';
$breakpoint_XXL: 'max-width: 1400px';
$breakpoint_XL: 'max-width: 1200px';
$breakpoint_LG: 'max-width: 992px';
$breakpoint_MD: 'max-width: 768px';
$breakpoint_SM: 'max-width: 576px';
$breakpoint_450: 'max-width: 450px';
$breakpoint_ESM: 'max-width: 385px';
$breakpoint_50: 'max-width: 350px';