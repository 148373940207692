footer {
    // background: radial-gradient(86.51% 322.58% at 88.03% 81.61%, #eeeeee1f 0%, #000000 63.54%, #41455073 100%);
    padding: 120px 0 40px;
    font-family: var(--font-Titillium);
    color: var(--txt-white);
    // clip-path: polygon(31.8% 9.6%, 65.8% 9.6%, 100% 0, 100% 99.4%, 66.2% 100%, 100% 100%, 0 100%, 0 0);
    background-color: #000;


    .__leftFooter {
        text-align: center;

        a {
            display: inline-block;
            text-decoration: none;
            color: var(--primary-color);

            img {
                height: 36px;
                width: auto;
            }
        }

        p {
            margin-top: 20px;
            font-size: 18px;

        }

        .nav-link {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            text-decoration: none;
            color: var(--txt-white);

            .__linkIcon {
                padding: 10px;
                background-color: var(--primary-color);
                border-radius: 10px;
            }

        }
    }


    h5 {
        margin-bottom: 15px;
    }

    .__center {
        margin-top: 20px;

        @media ($breakpoint_LG) {
            margin: 30px 0;
            padding: 20px 0;
            border-top: 1px solid #27241c;
        }

        .nav {
            display: flex;
            align-items: center;
            justify-content: center;

            @media ($breakpoint_SM) {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }

            .nav-item {
                display: inline-block;

                a {
                    width: 100%;
                    padding: 10px;
                    display: block;
                    text-decoration: none;
                    color: var(--txt-white);
                    transition: all 0.3s linear;

                    &:hover {
                        color: var(--primary-color);
                    }
                }
            }
        }

    }



    .avd {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        .avd_icon {
            padding: 10px;
            background-color: var(--primary-color);
            border-radius: 10px;
        }

        a {
            text-decoration: none;
            color: var(--txt-white);
        }
    }

    .__copyRyt {
        border-top: 1px solid #eeeeee42;
        text-align: center;
        padding-top: 40px;
        margin-top: 60px;

        p {
            margin-bottom: 0;
        }

    }

    .__links {

        .nav-item {
            display: block;
            width: 100%;
            margin-top: 10px;
        }

        .nav-link {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            text-decoration: none;
            color: var(--txt-white);

            .__linkIcon {
                padding: 10px;
                background-color: var(--primary-color);
                border-radius: 10px;
            }

        }


    }
}