.roadmap_page {
    padding-top: 112px;

    .roadmap-sec {
        position: relative;
        z-index: 1;

        &::after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-image: url("../Image/roadmap_bg.jpg");
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-attachment: fixed;
            filter: brightness(0.2);
        }

        .contact-content {
            padding: 120px 0;
            text-align: center;

            // backdrop-filter: blur(8px);
            h1 {
                color: $white;
            }

            .home-cont {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                gap: 5px;

                a {
                    color: $white;
                    text-decoration: none;
                }

                svg {
                    color: $white;
                    font-size: 15px;
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    .roadmap_sec {


        .heading {
            text-align: center;

            h4 {
                font-size: 12px;
                font-weight: 200;
                padding: 6px 20px;
                border: 1px solid #14a4d8;
                background-color: #14a4d814;
                border-radius: 50px;
                display: inline-block;
                color: #14a4d8;
            }

            h1 {
                font-size: 38px;
                font-weight: 400;
                color: white;
            }

            p {
                font-size: 12px;
                font-weight: 200;
                color: rgb(175, 175, 175);
            }
        }

        section.step.one {
            @media ($breakpoint_LG) {
                margin-top: 0 !important;
            }
        }

        .roadmap_img {
            height: 188px;
            width: auto;
            margin: auto;
        }

        .for_color {
            color: #14a4d8;
        }

        .for_bg {
            background-color: #14a4d814;
            border: 1px solid #14a4d882;
            width: 354px;

            h6 {
                white-space: nowrap;
                width: 76%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
                font-weight: 100;

                @media ($breakpoint_LG) {
                    padding-left: 16px !important;
                }

                span {
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }

        .foot_1 {
            transform: rotate(58deg);

            @media ($breakpoint_LG) {
                transform: rotate(90deg);
            }
        }

        .foot_2 {
            transform: rotate(68deg);

            @media ($breakpoint_LG) {
                transform: rotate(90deg);
            }
        }

        .foot_3 {
            transform: rotate(80deg);

            @media ($breakpoint_LG) {
                transform: rotate(90deg);
            }
        }

        .foot_4 {
            transform: rotate(106deg);

            @media ($breakpoint_LG) {
                transform: rotate(90deg);
            }
        }

        .foot_5 {
            transform: rotate(125deg);

            @media ($breakpoint_LG) {
                transform: rotate(90deg);
            }
        }

        .foot_6 {
            transform: rotate(120deg);

            @media ($breakpoint_LG) {
                transform: rotate(90deg);
            }
        }

        .foot_7 {
            transform: rotate(113deg);

            @media ($breakpoint_LG) {
                transform: rotate(90deg);
            }
        }

        .foot_8 {
            transform: rotate(95deg);

            @media ($breakpoint_LG) {
                transform: rotate(90deg);
            }
        }

        .foot_9 {
            transform: rotate(68deg);

            @media ($breakpoint_LG) {
                transform: rotate(90deg);
            }
        }

        .foot_10 {
            transform: rotate(50deg);

            @media ($breakpoint_LG) {
                transform: rotate(90deg);
            }
        }

        #infographic {
            .circle {
                @media ($breakpoint_LG) {
                    display: none;
                }
            }
        }

        #infographic {
            article {
                @media ($breakpoint_LG) {
                    width: 445px;
                }

                @media ($breakpoint_SM) {
                    width: 340px;
                }

                @media ($breakpoint_450) {
                    width: 300px;
                }

                &[data-step="1"] {
                    margin-left: 6px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="2"] {
                    margin-left: 54px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="3"] {
                    margin-left: 71px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="4"] {
                    margin-left: 61px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="5"] {
                    margin-left: 22px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="6"] {
                    margin-left: -5px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="7"] {
                    margin-left: -54px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="8"] {
                    margin-left: -70px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="9"] {
                    margin-left: -60px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="10"] {
                    margin-left: -21px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="11"] {
                    margin-left: 7px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="12"] {
                    margin-left: 55px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="13"] {
                    margin-left: 73px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="14"] {
                    margin-left: 62px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="15"] {
                    margin-left: 23px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }

                &[data-step="16"] {
                    margin-left: -102px;

                    @media ($breakpoint_LG) {
                        margin-left: 0;
                    }
                }



                @media ($breakpoint_LG) {
                    left: 0;
                }
            }

        }

        #infographic {
            &:nth-child(even) {
                article {
                    header {
                        @media ($breakpoint_LG) {
                            flex-flow: row;
                        }

                    }
                }
            }
        }



        #infographic .circle {
            width: 320px;
            height: 320px;
            border-radius: 50%;
            border: 7px double;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: 40px;
            position: absolute;
            left: 50%;
            margin: 40px 0;

            @media ($breakpoint_LG) {
                display: none;
            }
        }

        #infographic section:nth-child(odd) .circle {
            transform: translateX(-100%) translateY(-24px);
        }

        #infographic article {
            max-width: 354px;
            margin-bottom: 20px;
            cursor: pointer;
            left: 50%;
            position: relative;

            @media ($breakpoint_LG) {
                left: 0;

            }

            @media (max-width: 490px) {
                max-width: 300px;
            }

            @media (max-width: 390px) {
                max-width: 260px;
            }

            @media (max-width: 295px) {
                max-width: 215px;
            }
        }

        #infographic article header {
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;

            @media (max-width: 490px) {
                width: 100%;
            }
        }

        #infographic article header svg {
            display: flex;
            font-size: 58px;
            border-radius: 50%;
            background-clip: padding-box;
            transition: all 0.4s;
            color: #fff;
            border: 12px solid #216b89;
            margin: -16px 0 -16px -22px;
            background-color: #14a4d8;
        }

        #infographic article:hover header i {
            font-size: 28px;
        }

        #infographic article.active header i {
            transform: none;
        }

        #infographic article .body {
            background: var(--bgColor);
            padding: 0 20px;
            border-bottom-right-radius: 30px;
            border-bottom-left-radius: 30px;
            max-height: 0;
            transition: max-height 0.5s, padding 0.5s;
            overflow: hidden;
        }

        #infographic article .body .btn {
            padding: 3px 10px;
            text-transform: uppercase;
        }

        #infographic :nth-child(even) article {
            text-align: left;
            transform: translateX(-100%);

            @media ($breakpoint_LG) {
                transform: translateX(0%);
            }
        }

        #infographic :nth-child(even) article header {
            flex-flow: row-reverse;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;

            @media ($breakpoint_LG) {
                flex-flow: initial;
            }
        }

        #infographic :nth-child(even) article header i {
            margin: -16px -20px -16px 0;
        }

        #infographic section:nth-child(odd) article.active header {
            border-bottom-right-radius: 0;
        }

        #infographic section:nth-child(even) article.active header {
            border-bottom-left-radius: 0;
        }

        #infographic article.active .body {
            padding: 20px;
            max-height: 300px;
            border: 1px solid;
        }



        .mission_sec {
            margin-bottom: 70px;
            margin-top: 70px;

            .inner_sec {
                background-color: rgba(20, 164, 216, 0.0784313725);
                padding: 34px 120px;
                display: flex;
                align-items: center;
                gap: 110px;
                border-radius: 26px;
                overflow: hidden;
                border: 1px solid rgba(20, 164, 216, 0.5098039216);

                @media ($breakpoint_LG) {
                    flex-direction: column;
                    padding: 34px;
                    gap: 16px;
                    align-items: flex-start;
                }

                .img {
                    position: relative;
                    z-index: 9;

                    &::before {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        content: "";
                        width: 200px;
                        height: 390px;
                        background-image: linear-gradient(180deg, var(--primary-color) 0%, rgba(166, 161, 130, 0) 100%);
                        z-index: -1;
                        transform: rotate(15deg) translate(-156px, -226px);
                        bottom: 0;

                        @media ($breakpoint_LG) {
                            width: 148px;
                            height: 700px;
                            transform: rotate(270deg) translate(350px, 146px);
                        }
                    }

                    img {
                        height: 166px;
                        width: auto;

                        @media ($breakpoint_LG) {
                            height: 140px;
                        }
                    }
                }

                .content {
                    width: 100%;

                    h2 {
                        font-size: 2.5vw;
                        line-height: 125%;
                        font-weight: 700;
                        color: var(--primary-color);


                        @media ($breakpoint_SM) {
                            font-size: 4.5vw;
                        }

                    }

                    svg {
                        // font-size: 32px;
                        color: white;
                    }

                    p {
                        font-size: 1.8vw;
                        font-weight: 200;
                        color: rgba(255, 255, 255, 0.7019607843);
                        margin: 0;

                        @media ($breakpoint_SM) {
                            font-size: 1.8vw;
                        }
                    }
                }


            }

            .btn_new {
                background: var(--q-btn);
                color: var(--txt-white);
                border: 1px solid transparent;
                padding: 10px 25px;
                border-radius: var(--q-radius);
                text-decoration: none;
                transition: all 0.4s linear;

                &:hover {
                    background: transparent;
                    border-color: var(--q-border);
                }
            }
        }

    }
}