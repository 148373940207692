.__policy{
    padding: 150px 0;

    .__policy__sec{
        color: var(--txt-white);

        h4{
            margin-bottom: 20px;
        }

        .__datae{
            color: var(--primary-color);
        }

        p{
            opacity: 0.7;

            a{
                text-decoration: none;
                color: var(--primary-color);
            }
        }
    }
}