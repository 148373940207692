.apps__ {
    color: var(--txt-white);

    .row {
        row-gap: 15px;
    }

    .team__banner {
        position: relative;
        z-index: 1;
        overflow: hidden;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 70px;

        @media ($breakpoint_LG) {
            padding: 70px 50px 0;
        }

        @media ($breakpoint_MD) {
            height: auto;
            padding: 70px 0 50px;
        }


        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-image: url(../Image/tiffany-blur.webp);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0.4;
        }

        .team-data {
            text-align: center;
            max-width: 900px;
            width: 100%;
            margin: 50px auto 0;
            padding: 0 15px;
            transition: all 0.4s linear;

            .__img {

                @media ($breakpoint_MD) {
                    margin-bottom: 20px;
                }

                img {
                    width: 30%;
                    transform: rotate(5deg);
                }

            }

            h3 {
                font-size: 44px;
                font-weight: 600;
                margin-bottom: 0px;

                @media ($breakpoint_MD) {
                    font-size: 33px;
                    margin-bottom: 20px;
                }

                @media ($breakpoint_SM) {
                    font-size: 28px;
                }
            }
        }


        p {
            margin-bottom: 0;
            opacity: 0.6;

            @media ($breakpoint_MD) {
                font-size: 16px;
            }

            @media ($breakpoint_SM) {
                font-size: 15px;
            }

            a {
                text-decoration: none;
                display: inline-block;
                margin-top: 40px;
                background: var(--q-btn);
                color: var(--txt-white);
                border: 1px solid transparent;
                padding: 8px 25px;
                border-radius: var(--q-radius);
                text-decoration: none;
                transition: all 0.4s linear;

                svg {
                    margin-left: 10px;
                }

                &:hover {
                    background: transparent;
                    border-color: var(--q-border);
                }

            }
        }
    }


    .__app-download {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 100px 0 50px;
        overflow: hidden;

        .row{
            align-items: center;

            @media ($breakpoint_LG){
                flex-direction: column-reverse;
            }
        }

        .heading_sec{
            text-align: center;

            @media ($breakpoint_LG){
                display: none;
            }

            h1 {
                font-size: 2em;
                margin-bottom: 0;
                display: block;
                position: relative;
                padding-bottom: 25px;

                &::after{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 20%;
                    height: 8px;
                    border-radius: 20px;
                    background-color: var(--primary-color);
                    transform: translate(-50% , -50%);
                }
              }
        }


        .left-section {
            padding-right: 40px;

              
              h2{
                // font-size: 1.5em;
                margin-bottom: 1em;

              }
              
              p {
                font-size: 14px;
                margin-bottom: 2em;
                opacity: 0.6;
              }

              .buttons{

                .button {
                    display: inline-block;
                    padding: 8px 30px;
                    margin-right: 10px;
                    background-color: transparent;
                    border: 1px solid var(--q-border);
                    color: #fff;
                    text-decoration: none;
                    border-radius: 30px;
                    transition: all 0.3s linear;

                    &:hover{
                        background-color: var(--primary-color);
                    }

                    &:nth-child(1){
                        background-color: var(--primary-color);
                    }
                  }
              }
        }


        .right-section {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;

            &::after{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                height: 100%;
                width: 100%;
                transform: translate(-50%, -50%);
                background-image: url(../Image/outer-radius.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                z-index: -1;
            }

            img {
                max-width: 80%;
                height: auto;

                @media ($breakpoint_LG){
                    max-width: 40%;
                }

                @media ($breakpoint_SM){
                    max-width: 50%;
                }
            }
        }
    }

    .intro {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 70px 0 0;
        overflow: hidden;
        // border-top: 1px solid rgba(255, 255, 255, 0.0509803922);
    background-color: rgba(255, 255, 255, 0.0392156863);

        .row{
            align-items: center;
        }

        .heading_sec{
            text-align: center;
            max-width: 800px;
            width: 100%;
            margin: 0 auto 30px;

            h1 {
                font-size: 1.8rem;
                margin-bottom: 0;
                display: block;
                position: relative;
                padding-bottom: 25px;

                @media ($breakpoint_SM){
                    font-size: 1.5rem;
                }

                &::after{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 20%;
                    height: 8px;
                    border-radius: 20px;
                    background-color: var(--primary-color);
                    transform: translate(-50% , -50%);
                }
              }
        }

        .intro-left {
            padding-right: 40px;

            @media ($breakpoint_LG){
                padding-right: 0;
            }

              
              h2{
                margin-bottom: 10px;

              }
              
              p {
                font-size: 14px;
                opacity: 0.6;
                margin-bottom: 0;
              }

              .buttons{

                .button {
                    display: inline-block;
                    padding: 8px 30px;
                    margin-right: 10px;
                    background-color: transparent;
                    border: 1px solid var(--q-border);
                    color: #fff;
                    text-decoration: none;
                    border-radius: 30px;
                    transition: all 0.3s linear;

                    &:hover{
                        background-color: var(--primary-color);
                    }

                    &:nth-child(1){
                        background-color: var(--primary-color);
                    }
                  }
              }
        }


        .intro-right {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;

            @media ($breakpoint_MD){
                justify-content: flex-start;
                align-items: flex-start;
            }

            &::after{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                height: 100%;
                width: 100%;
                transform: translate(-50%, -50%);
                background-image: url(../Image/outer-radius.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                z-index: -1;

                @media ($breakpoint_MD){
                    top: 0;
                    left: -33px;
                    transform: none;
                    width: 60%;
                }
            }

            img {
                max-width: 60%;
                height: auto;
                transform: scale(1.1);

                @media ($breakpoint_LG){
                    max-width: 40%;
                    transform: none;
                }

                @media ($breakpoint_SM){
                    max-width: 50%;
                }
            }
        }
    }

    .intro-second{
        padding-top: 0;

        @media ($breakpoint_LG){
            padding-top: 70px;
        }

        .row{
            @media ($breakpoint_LG){
                flex-direction: column-reverse;
            }
        }

        .intro-left{
            text-align: right;
            padding-right: 0;

            @media ($breakpoint_LG){
                text-align: left;
            }
        }
    }


    .activity-sec{
        padding: 130px 0;

        .row{
            row-gap: 20px;
        }

        .activ-card{
            background-color: #232324;
            padding: 30px;
            border-radius: 30px;
            height: 100%;
            transition: all 0.3s linear;

            &:hover{
                background-color: var(--primary-color);

                .__icon{
                    background-color: #232324;
                }
            }

            .__icon{
                padding: 15px;
                border-radius: 30px;
                display: inline-block;
                align-items: center;
                justify-content: center;
                background-color: var(--primary-color);
                margin-bottom: 14px;

                svg{
                    font-size: 33px;
                }
            }

            h5{
                margin-bottom: 20px;

            }

            p{
                font-size: 14px;
                opacity: 0.6;
                margin-bottom: 0;
            }

        }
    }

    .__Awaits{
        text-align: center;
        padding: 100px 0;

        .__img{
            margin-bottom: 20px;

            svg{
                font-size: 66px;
                color: var(--primary-color);
            }
        }

        h3{
            margin-bottom: 2rem;
        }

        p{
            opacity: 0.6;
        }
    }
}


