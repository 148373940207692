@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

:root {
    //todo commmon variables
    --bodya: #dcdcdc42;
    --txt-white: #fff;
    --txt-black: #000;
    --primary-color: #14a4d8;
    --BannerBG: #1a1a1b;
    --secondBG: #1ca0d00d;
    --gradient-primary: #14a4d8;




    //todo font-family
    --font-Ubuntu: "Ubuntu", sans-serif;
    --font-Montserrat: "Montserrat", sans-serif;
    --font-Titillium: "Titillium Web", sans-serif;
    --font-Poppins: "Poppins", sans-serif;




    //todo Header using pvt variables
    --pHeader: #ffffff0a;
    --pHeaderShow: #000;


    //todo all Btn variables
    --q-btn: var(--gradient-primary);
    --q-border: #eeeeee8e;
    --q-radius: 30px;
}